<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="checkLabelInformation"
      @keydown.enter.prevent="() => {}"
    >
      
      <div class="form-group-wrapper">
        <!-- Type - Name - Description-->
        <!-- Title -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('LABELS.CATEGORY')} '(*)'`"
            :placeholder="$t('LABELS.CATEGORY')"
            v-model="label.category"
          >
            <el-select
              :name="`${$t('LABELS.CATEGORY')}`"
              v-model.lazy="label.category"
              :placeholder="$t('LABELS.CATEGORY')"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(label, val) in labelCategories"
                :key="val"
                :value="val"
                :label="`${$t('LABELS.' + val)}`"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.category" />
        </div>

        <!-- Name -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.NAME')} '(*)'`"
            v-model="label.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </div>

        <!-- Excerpt -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                :placeholder="$t('COMMON.EXCERPT')"
                v-model="label.excerpt"
              >
            </el-input>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              label.id
                ? $t("LABELS.EDIT_LABEL")
                : $t("LABELS.ADD_LABEL")
            }}
          </base-button>
        </div>
      </div>
    </form>

    <modal
      :show="showLabelCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("LABELS.SAME_LABELS", {
                name: label.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="labels"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('LABELS.CATEGORY')"
                prop="category"
                min-width="100"
              >
                <template v-slot="{ row }">
                <span class="text-uppercase">
                  {{$t("LABELS." + row?.category) }}
                </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
              <template v-slot="{ row }">
                {{ row.excerpt ?? '' }}
              </template>
            </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleLabelCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { LABEL_CATEGORIES } from "@/constants/labels";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: [
    "labelData",
    "formErrors",
    "loading",
    "hideSubmit",
  ],

  data() {
    let labelData = { ...this.labelData };
    return {
      label: labelData,
      labelCategories: LABEL_CATEGORIES,
      labels: [],
      total: 0,
      showModal: false,
      showLabelCheckModal: false,
      processing: false,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      const checkUniqueLabel = await this.checkLabelUniqueInformation();
      if (checkUniqueLabel) {
        await this.sendInformation();
      } else {
        this.showLabelCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.label.name) {
        this.label.name = this.label.name.toString();
      }
      let labelData = cloneDeep(this.label);
      this.$emit("labelSubmitted", labelData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.label?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.label?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          }
        };
        await this.$store.dispatch("labels/list", params);
        this.labels = this.$store.getters["labels/list"];
        this.total = this.$store.getters["labels/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkLabelUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.label?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleLabelCheckModalState();
      await this.sendInformation();
    },

    async toggleLabelCheckModalState() {
      this.showLabelCheckModal = !this.showLabelCheckModal;
    },

    checkLabelInformation() {
      if (
        this.label.name &&
        this.label.category
      ) {
        this.handleSubmit();
        this.showModal = false;
      } else {
        this.showModal = true;
      }
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    labelData(labelData) {
      if (labelData) {
        this.label = {
          ...this.label,
          ...cloneDeep(labelData),
        };
      }
    },
  },
};
</script>
