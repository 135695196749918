<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ `${label.name}` }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("LABELS.CATEGORY") }}</dt>
        <dd class="col-sm-8 text-uppercase" v-if="label?.category">
          {{ $t("LABELS." + label?.category) }}
        </dd>
      </dl>
      <dl class="row" v-if="label.slug">
        <dt class="col-sm-4">{{ $t("LABELS.KEY") }}</dt>
        <dd class="col-sm-8">
          {{ label.slug ?? "" }}
        </dd>
      </dl>
      <dl class="row" v-if="label.name">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ label.name ?? "" }}
        </dd>
      </dl>
      <dl class="row" v-if="label.excerpt">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          {{ label.excerpt ?? "" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ label.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ label.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "label-view-global",

  props: ["label"],

  data() {
    return {};
  },

  created() {},

  methods: {
    labelUpdated() {
      this.$emit("labelUpdated", true);
    },
  },

  mounted() {},

  watch: {
    label(label) {},
  },
};
</script>
